<template>
  <v-col cols="12" class="pt-0 px-0">
    <v-row class="ma-0 my-3">
      <v-chip :color="color" class="mb-3" label :text-color="textColor">
        <v-icon left> mdi-label</v-icon>
        Add Parts
      </v-chip>
      <v-col cols="12" class="px-0">
        <v-combobox v-model="model" :filter="filter" :hide-no-data="!search" :items="items"
                    :search-input.sync="search" hide-selected label="Add Parts" hide-details="auto"
                    multiple small-chips solo @change="updateParts">
          <template v-slot:no-data>
            <v-list-item>
              <span class="subheading">Create</span>
              <v-chip color="grey lighten-3" label small>
                {{ search }}
              </v-chip>
            </v-list-item>
          </template>
          <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip v-if="item === Object(item)" v-bind="attrs" color="grey lighten-3"
                    :input-value="selected" label small>
              <span class="pr-2 text-capitalize"> {{ item.text }} </span>
              <v-icon small @click="parent.selectItem(item)"> $delete</v-icon>
            </v-chip>
          </template>
          <template v-slot:item="{ index, item }">
            <v-text-field v-if="editing === item" v-model="editing.text" autofocus flat
                          background-color="transparent" hide-details solo @keyup.enter="edit(index, item)"></v-text-field>
            <v-chip v-else :color="`${item.color} lighten-3`" dark label small class="text-capitalize">
              {{ item.text }}
            </v-chip>
            <v-spacer></v-spacer>
            <v-list-item-action @click.stop>
              <v-btn icon @click.stop.prevent="edit(index, item)">
                <v-icon>{{ editing !== item ? 'mdi-pencil' : 'mdi-check' }}</v-icon>
              </v-btn>
            </v-list-item-action>
          </template>
        </v-combobox>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  props: {
    parts: {
      type: Array,
      default: () => []
    },
    lists: {
      type: Array,
      default: () => []
    },
    color: {
      type: String,
      default: 'primary'
    },
    textColor: {
      type: String
    }
  },
  emit: ['partsUpdate'],
  data: (vm) => ({
    activator: null,
    attach: null,
    editing: null,
    editingIndex: -1,
    nonce: 1,
    menu: false,
    model: [],
    x: 0,
    search: null,
    y: 0,
    items: [{ header: 'Select parts or create one' }]
  }),
  watch: {
    lists(newValue) {
      if (newValue.length) {
        this.model = newValue.map(item => ({ text: item, value: item }));
      }
    },
    model(val, prev) {
      if (val.length === prev.length) return;
      this.model = val.map(v => {
        if (typeof v === 'string') {
          v = { text: v, value: v.toLowerCase() }
          this.items.push(v)
          this.nonce++
        }
        return v
      })
    },
    parts(newValue) {
      if (newValue.length) {
        this.items = [...this.items, ...this.parts]
      }
    },
  },
  methods: {
    edit(index, item) {
      if (!this.editing) {
        this.editing = item
        this.editingIndex = index
      } else {
        this.editing = null
        this.editingIndex = -1
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false

      const hasValue = val => val != null ? val : ''

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1
    },
    updateParts() {
      const PARTS = this.model.map(item => {
        return typeof item === 'string' ? item.toLowerCase() : item.value;
      });
      this.$emit('partsUpdate', PARTS);
    }
  },
}
</script>